import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useAppStore = defineStore("app", () => {
  const showImportPopup = ref(false);
  const showExportPopup = ref(false);
  const showDemoPopup = ref(false);
  const showDeleteAccountPopup = ref(false);
  const showNewWorkspacePopup = ref(false);
  const user = ref(null);

  const setShowNewWorkspacePopup = (value) => {
    console.log("Setting showNewWorkspacePopup to", value);
    showNewWorkspacePopup.value = value;
  };

  return {
    showImportPopup,
    showDemoPopup,
    showNewWorkspacePopup,
    user,
    setShowNewWorkspacePopup,
  };
});
