<template>
  <div class="collaboration-screen">
    <h1>How will you be using AudioScribe?</h1>
    
    <form @submit.prevent="handleSubmit" class="login-form" :class="{ 'error-active': hasErrors }">
      <div class="form-section">
        <div class="collaboration-options">
          <div 
            v-for="option in collaborationOptions" 
            :key="option.value"
            class="option-card"
            :class="{ 'selected': preferences.collaborationType === option.value }"
            @click.prevent="selectCollaborationType(option.value)"
          >
            <div class="radio-container">
              <span 
                class="radio-circle" 
                :class="{ 'checked': preferences.collaborationType === option.value }"
              ></span>
              <span class="radio-text">{{ option.label }}</span>
            </div>
          </div>
        </div>
        
        <div v-if="validationErrors.collaborationType" class="form-error">
          <span class="error-icon icon-exclamation-mark"></span>
          <span class="error-txt">{{ validationErrors.collaborationType }}</span>
        </div>
      </div>
      
      <div v-if="preferences.collaborationType === 'TEAM'" class="form-section team-size-section">
        <h2>How many people in your team?</h2>
        <div class="team-size-options">
          <div 
            v-for="option in teamSizeOptions" 
            :key="option.value"
            class="option-card"
            :class="{ 'selected': preferences.teamSize === option.value }"
            @click.prevent="selectTeamSize(option.value)"
          >
            <div class="radio-container">
              <span 
                class="radio-circle" 
                :class="{ 'checked': preferences.teamSize === option.value }"
              ></span>
              <span class="radio-text">{{ option.label }}</span>
            </div>
          </div>
        </div>
        
        <div v-if="validationErrors.teamSize" class="form-error">
          <span class="error-icon icon-exclamation-mark"></span>
          <span class="error-txt">{{ validationErrors.teamSize }}</span>
        </div>
      </div>
      
      <div class="navigation-buttons">
        <button type="button" class="btn btn-secondary" @click="$emit('prevStep')">Back</button>
        <input type="submit" class="btn btn-primary" value="Submit" :disabled="isSubmitting" />
      </div>
    </form>
  </div>
</template>

<script>
import { useOnboardingStore } from '@/stores/onboarding-store';
import { mapStores } from 'pinia';

export default {
  name: 'TeamCollaborationScreen',
  computed: {
    ...mapStores(useOnboardingStore),
    preferences() {
      return this.onboardingStore.preferences;
    },
    validationErrors() {
      return this.onboardingStore.validationErrors;
    },
    isSubmitting() {
      return this.onboardingStore.isSubmitting;
    },
    hasErrors() {
      return Object.keys(this.validationErrors).length > 0;
    }
  },
  data() {
    return {
      collaborationOptions: [
        { value: 'SOLO', label: 'Just me' },
        { value: 'TEAM', label: 'With a team' }
      ],
      teamSizeOptions: [
        { value: 'TINY', label: '2-5 people' },
        { value: 'SMALL', label: '6-10 people' },
        { value: 'MEDIUM', label: '11-20 people' },
        { value: 'LARGE', label: '20+ people' }
      ]
    };
  },
  methods: {
    selectCollaborationType(value) {
      this.preferences.collaborationType = value;
      
      // Clear team size if not using with a team
      if (value !== 'TEAM') {
        this.preferences.teamSize = null;
      }
      
      this.onboardingStore.validationErrors = {};
    },
    selectTeamSize(value) {
      this.preferences.teamSize = value;
      this.onboardingStore.validationErrors = {};
    },
    handleSubmit() {
      if (this.onboardingStore.validateCurrentStep()) {
        this.$emit('complete');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/abstracts/variables";
@import "@/assets/abstracts/mixins";

.collaboration-screen {
  h1 {
    font-size: 22px;
    margin-bottom: 24px;
    color: $white;
    text-align: center;
    font-weight: 500;
    
    @media (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
}

.form-section {
  margin-bottom: 30px;
  
  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 500;
    color: $white;
  }
}

.collaboration-options,
.team-size-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 15px;
}

.team-size-section {
  background-color: rgba($black, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-top: 24px;
  border: 1px solid rgba($white, 0.05);
}

.option-card {
  background-color: rgba($black, 0.2);
  border: 1px solid $gray900;
  border-radius: 10px;
  padding: 14px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  
  &:hover {
    background-color: rgba($primary, 0.05);
  }
  
  &.selected {
    border-color: $primary;
    background-color: rgba($primary, 0.1);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  .radio-container {
    position: relative;
    z-index: 2;
  }
}

.radio-container {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.radio-circle {
  display: inline-block;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid $gray700;
  position: relative;
  margin-right: 12px;
  transition: all 0.2s ease;
  
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $primary;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  
  &.checked {
    border-color: $primary;
    
    &::after {
      display: block;
    }
  }
}

.radio-text {
  font-size: 16px;
  color: $white;
  font-weight: 400;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  
  .btn {
    min-width: 120px;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &.btn-primary {
      background-color: $primary;
      color: $white;
      
      &:hover {
        background-color: darken($primary, 10%);
      }
      
      &:disabled {
        background-color: $gray700;
        cursor: not-allowed;
      }
    }
    
    &.btn-secondary {
      background-color: transparent;
      color: $white;
      border: 1px solid $gray700;
      
      &:hover {
        background-color: rgba($white, 0.05);
        border-color: $gray500;
      }
    }
  }
}

.form-error {
  color: $danger;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  
  .error-icon {
    margin-right: 5px;
  }
}
</style>