<template>
  <div class="logo">
    <router-link to="/"
      ><img
        src="/images/logo-dark.svg"
        width="120"
        height="60"
        alt="Audioscribe"
        class="logo-img"
    /></router-link>
  </div>
</template>

<style scoped>
.logo-img {
  cursor: grab;
  max-width: 100%;
  height: auto;
}
</style>
