<template>
  <div class="purpose-screen">
    <h1>What's your primary purpose for using AudioScribe?</h1>
    
    <form @submit.prevent="handleSubmit" class="login-form" :class="{ 'error-active': hasError }">
      <div class="purpose-options">
        <div 
          v-for="option in purposeOptions" 
          :key="option.value"
          class="option-card"
          :class="{ 'selected': preferences.primaryPurpose === option.value }"
          @click.prevent="selectPurpose(option.value)"
        >
          <div class="radio-container">
            <span 
              class="radio-circle" 
              :class="{ 'checked': preferences.primaryPurpose === option.value }"
            ></span>
            <span class="radio-text">
              <span class="option-title">{{ option.title }}</span>
              <span v-if="option.description" class="option-description">{{ option.description }}</span>
            </span>
          </div>
        </div>
      </div>
      
      <div v-if="preferences.primaryPurpose === 'OTHER'" class="form-input other-description">
        <input
          type="text"
          id="otherPurposeDescription"
          placeholder="Please describe your use case"
          v-model="preferences.otherPurposeDescription"
        />
        <div class="form-error" v-if="validationErrors.otherPurposeDescription">
          <span class="error-icon icon-exclamation-mark"></span>
          <span class="error-txt">{{ validationErrors.otherPurposeDescription }}</span>
        </div>
      </div>
      
      <div v-if="hasError" class="form-error">
        <span class="error-icon icon-exclamation-mark"></span>
        <span class="error-txt">{{ validationErrors.primaryPurpose }}</span>
      </div>
      
      <div class="btn-holder">
        <input type="submit" class="btn" value="Next" :disabled="isSubmitting" />
      </div>
    </form>
  </div>
</template>

<script>
import { useOnboardingStore } from '@/stores/onboarding-store';
import { mapStores } from 'pinia';

export default {
  name: 'PrimaryPurposeScreen',
  computed: {
    ...mapStores(useOnboardingStore),
    preferences() {
      return this.onboardingStore.preferences;
    },
    validationErrors() {
      return this.onboardingStore.validationErrors;
    },
    isSubmitting() {
      return this.onboardingStore.isSubmitting;
    },
    hasError() {
      return !!this.validationErrors.primaryPurpose;
    }
  },
  data() {
    return {
      purposeOptions: [
        { 
          value: 'PROFESSIONAL', 
          title: 'Professional Use',
          description: 'business meetings, interviews, legal'
        },
        { 
          value: 'ACADEMIC', 
          title: 'Academic',
          description: 'research, lectures, studying'
        },
        { 
          value: 'MEDIA', 
          title: 'Media Content',
          description: 'YouTube, Instagram, podcasts, social media'
        },
        { 
          value: 'PERSONAL', 
          title: 'Personal Use',
          description: 'messages, notes, personal recordings'
        },
        { 
          value: 'GAMING', 
          title: 'Gaming',
          description: 'RPGs, game sessions, livestreams'
        },
        { 
          value: 'RELIGIOUS', 
          title: 'Religious Content',
          description: 'sermons, community content'
        },
        { 
          value: 'OTHER', 
          title: 'Other',
          description: ''
        }
      ]
    };
  },
  methods: {
    selectPurpose(value) {
      this.preferences.primaryPurpose = value;
      
      // If switching away from OTHER, clear the description
      if (value !== 'OTHER') {
        this.preferences.otherPurposeDescription = null;
      }
      
      // Clear validation errors
      this.onboardingStore.validationErrors = {};
    },
    handleSubmit() {
      if (this.onboardingStore.validateCurrentStep()) {
        this.$emit('nextStep');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/abstracts/variables";
@import "@/assets/abstracts/mixins";

.purpose-screen {
  h1 {
    font-size: 22px;
    margin-bottom: 24px;
    color: $white;
    text-align: center;
    font-weight: 500;
    
    @media (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
}

.purpose-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.option-card {
  background-color: rgba($black, 0.2);
  border: 1px solid $gray900;
  border-radius: 10px;
  padding: 14px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  
  &:hover {
    background-color: rgba($primary, 0.05);
  }
  
  &.selected {
    border-color: $primary;
    background-color: rgba($primary, 0.1);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  .radio-container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-start;
  }
}

.radio-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  text-align: left;
  
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.radio-circle {
  display: inline-block;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid $gray700;
  position: relative;
  margin-right: 12px;
  margin-top: 3px; /* Align with first line of text */
  transition: all 0.2s ease;
  flex-shrink: 0;
  
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $primary;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  
  &.checked {
    border-color: $primary;
    
    &::after {
      display: block;
    }
  }
}

.radio-text {
  display: flex;
  flex-direction: column;
  color: $white;
  width: 100%;
  text-align: left;
  
  .option-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: left;
  }
  
  .option-description {
    font-size: 14px;
    color: rgba($white, 0.7);
    font-weight: 400;
    font-style: italic;
    text-align: left;
  }
}

.form-input {
  margin-top: 15px;
}

.other-description input {
  background-color: rgba($black, 0.2);
  border: 1px solid $gray900;
  color: $white;
  padding: 12px 16px;
  border-radius: 10px;
  
  &:focus {
    border-color: $primary;
    outline: none;
  }
}

.btn-holder {
  margin-top: 30px;
  
  .btn {
    background-color: $primary;
    color: $white;
    border: none;
    border-radius: 25px;
    padding: 12px 24px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    display: block;
    
    &:hover {
      background-color: darken($primary, 10%);
    }
    
    &:disabled {
      background-color: $gray700;
      cursor: not-allowed;
    }
  }
}

.form-error {
  color: $danger;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  
  .error-icon {
    margin-right: 5px;
  }
}
</style>