<template>
  <div class="main-nav_section">
    <ul class="nav-list">
      <NavLink
        :path="`/${activeWorkspaceUUID}`"
        label="Home"
        icon="icon-home"
      ></NavLink>
      <NavLink
        :path="`/${activeWorkspaceUUID}/conversations`"
        label="Conversations"
        icon="icon-chat"
      ></NavLink>
    </ul>
  </div>
</template>

<script>
import { useWorkSpaceStore } from "@/stores/workspace-store";
import NavLink from "./NavLink.vue";

export default {
  name: "NavLinks",
  components: { NavLink },
  computed: {
    activeWorkspaceUUID() {
      const store = useWorkSpaceStore();
      return store.activeWorkspaceUUID;
    },
  },
};
</script>
