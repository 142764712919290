import { callExternalApi } from "@/services/external-api.service";
import { auth0 } from "@/services/auth0";

const API_URL = import.meta.env.VITE_API_SERVER_URL;

/**
 * Get the user's onboarding status
 * @returns {Promise<Object>} Response with onboarding status and preferences
 */
export const getOnboardingStatus = async () => {
  try {
    const token = await auth0.getAccessTokenSilently();
    
    const config = {
      url: `${API_URL}/api/users/onboarding-status`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return await callExternalApi({ config });
  } catch (error) {
    console.error("Error getting onboarding status:", error);
    return { data: null, error: { message: "Failed to get onboarding status" } };
  }
};

/**
 * Save user onboarding preferences
 * @param {Object} preferences - User preferences to save
 * @returns {Promise<Object>} Response with success status
 */
export const saveOnboardingPreferences = async (preferences) => {
  try {
    const token = await auth0.getAccessTokenSilently();
    
    const config = {
      url: `${API_URL}/api/users/onboarding-preferences`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: preferences,
    };

    return await callExternalApi({ config });
  } catch (error) {
    console.error("Error saving onboarding preferences:", error);
    return { data: null, error: { message: "Failed to save preferences" } };
  }
};

/**
 * Reset onboarding status (DEVELOPMENT ONLY)
 * This will reset the onboarding status for the current user,
 * allowing them to go through the onboarding flow again.
 * @returns {Promise<Object>} Response with success status
 */
export const resetOnboardingStatus = async () => {
  try {
    const token = await auth0.getAccessTokenSilently();
    
    const config = {
      url: `${API_URL}/api/users/reset-onboarding`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    return await callExternalApi({ config });
  } catch (error) {
    console.error("Error resetting onboarding status:", error);
    return { data: null, error: { message: "Failed to reset onboarding status" } };
  }
};