<template>
  <div
    class="popup-holder popup-workspace"
    :style="[showNewWorkspacePopup ? '' : 'display:none']"
  >
    <div class="popup-wrap">
      <div class="popup-head">
        <h2>Add New Workspace</h2>
        <a
          @click="closeNewWorkspacePopup"
          href="#"
          class="popup-close icon-close"
        ></a>
      </div>
      <div class="popup-body">
        <form
          @submit.prevent="handleSubmit"
          :class="{ 'login-form': true, 'error-active': error }"
        >
          <div class="form-input">
            <input
              type="text"
              id="workspace"
              name="workspace"
              placeholder="Workspace Name"
              v-model="workspaceName"
              @input="clearError"
            />
            <div class="form-error" v-if="error">
              <span class="error-icon icon-exclamation-mark"></span>
              <span class="error-txt">{{ error }}</span>
              <div v-if="error.includes('upgrade')" class="upgrade-prompt">
                <router-link to="/settings/plans" class="upgrade-link">
                  Upgrade to Premium
                </router-link>
              </div>
            </div>
          </div>
          <div class="btn-holder">
            <input type="submit" class="btn" value="Create Workspace" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app-store";
import { useWorkSpaceStore } from "@/stores/workspace-store"; // Import workspace store
import { mapStores } from "pinia";
import { createWorkspace } from "@/services/workspace.service";

export default {
  name: "NewWorkspacePopup",
  props: ["showNewWorkspacePopup"],
  computed: {
    ...mapStores(useAppStore),
  },
  data() {
    return {
      workspaceName: "",
      error: "",
    };
  },
  watch: {
    showNewWorkspacePopup(newVal) {
      if (newVal) {
        document.addEventListener("keydown", this.handleEscape);
      } else {
        document.removeEventListener("keydown", this.handleEscape);
      }
    },
  },
  methods: {
    closeNewWorkspacePopup() {
      console.log("Closing the new workspace popup");
      this.appStore.showNewWorkspacePopup = false;
    },
    clearError() {
      this.error = "";
    },
    async handleSubmit() {
      // Validation
      if (!this.workspaceName.trim()) {
        this.error = "Workspace name cannot be empty.";
        return;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.workspaceName)) {
        this.error =
          "Workspace name invalid. Only alphanumeric characters and spaces are allowed.";
        return;
      }

      // Check workspace limit based on subscription status
      const workspaceStore = useWorkSpaceStore();

      // Check if user is on free plan and already has 1 workspace
      if (
        !workspaceStore.isSubscribedState &&
        workspaceStore.workspaces.length >= 1
      ) {
        this.error =
          "Free plan limited to 1 workspace. Please upgrade for up to 3 workspaces.";
        return;
      }

      // Check if paid user has reached their limit of 3 workspaces
      if (
        workspaceStore.isSubscribedState &&
        workspaceStore.workspaces.length >= 3
      ) {
        this.error = "Maximum limit of 3 workspaces reached.";
        return;
      }

      try {
        // API Call
        const response = await createWorkspace(this.workspaceName, "PRIVATE");
        console.log("Workspace created successfully");

        // Update the workspace store
        await workspaceStore.fetchWorkspaces(); // Fetch updated workspaces
        workspaceStore.setActiveWorkspace(response.data.workspaceUUID); // Set the new workspace as active

        this.workspaceName = ""; // Clear the input field
        this.closeNewWorkspacePopup();
      } catch (err) {
        console.error("Error creating workspace:", err);
        this.error = "Failed to create workspace. Please try again later.";
      }
    },
    handleEscape(event) {
      if (event.key === "Escape") {
        this.closeNewWorkspacePopup();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscape);
  },
};
</script>

<style scoped>
.upgrade-prompt {
  margin-top: 10px;
  text-align: center;
}

.upgrade-link {
  color: #f16622;
  text-decoration: underline;
  font-weight: 500;
}

.upgrade-link:hover {
  text-decoration: none;
}
</style>
