<template>
  <div
    class="popup-holder popup-delete-account"
    :style="[showDeleteAccountPopup ? '' : 'display:none']"
  >
    <div class="popup-wrap">
      <div class="popup-head">
        <h2>Delete Account</h2>
        <a href="#" class="popup-close icon-close" @click="closePopup">✕</a>
      </div>
      <div class="popup-body">
        <div class="warning-message">
          <div class="icon-warning"></div>
          <p><strong>Are you sure you want to delete your account?</strong></p>
        </div>
        <p>If you delete your account:</p>
        <ul class="info-list">
          <li>Your subscription will be canceled.</li>
          <li>All your uploaded media files will be deleted.</li>
          <li>All your transcripts will be deleted.</li>
          <li>All your folders will be deleted.</li>
          <li>Your account will be deleted.</li>
        </ul>
        <p>
          <strong>This takes effect immediately and can't be undone.</strong>
        </p>
        <p>You won't be able to use AudioScribe unless you sign up again.</p>
        <p>Are you sure this is what you want?</p>
        <label class="checkbox-label">
          <input
            type="checkbox"
            v-model="confirmDelete"
            style="margin-right: 5px"
          />
          Yes, I'm sure I want to delete my account. I understand this is final
          and can't be undone.
        </label>
        <div class="btn-list">
          <a href="#" class="btn btn-secondary-outline" @click="closePopup"
            >Cancel</a
          >
          <a
            href="#"
            class="btn btn-danger"
            :class="{ disabled: !confirmDelete }"
            @click="deleteAccount"
            :disabled="!confirmDelete"
            >Delete Account</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

export default {
  name: "DeleteAccountPopup",
  props: ["showDeleteAccountPopup"],
  data() {
    return {
      confirmDelete: false,
    };
  },
  computed: {
    ...mapStores(useAppStore),
  },
  methods: {
    openPopup() {
      this.show = true;
    },
    closePopup() {
      this.show = false;
      this.confirmDelete = false;
    },
    async deleteAccount() {
      // Handle account deletion API call
    },
  },
};
</script>

<style scoped>
.popup-delete-account .warning-message {
  display: flex;
  align-items: center;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.popup-delete-account .warning-message .icon-warning {
  background: url("/path-to-your-warning-icon.svg") no-repeat center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.popup-delete-account .info-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0 0 15px 0;
}

.popup-delete-account .info-list li {
  margin-bottom: 10px;
}

.popup-delete-account .checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.popup-delete-account .btn-list {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.popup-delete-account .btn-secondary-outline {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 15px;
  text-decoration: none;
  border-radius: 5px;
}

.popup-delete-account .btn-secondary-outline:hover {
  background-color: #e2e6ea;
  text-decoration: none;
}

.popup-delete-account .btn-danger {
  background-color: #dc3545;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.popup-delete-account .btn-danger.disabled {
  background-color: #f8d7da;
  color: #721c24;
  cursor: not-allowed;
}

.popup-delete-account .btn-danger.disabled:hover {
  text-decoration: none;
}

.popup-delete-account .btn-danger:hover:not(.disabled) {
  background-color: #c82333;
  text-decoration: none;
}

.popup-close {
  font-size: 1.5rem;
  color: #6c757d;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  text-decoration: none;
}

.popup-close:hover {
  color: #000;
}
</style>
