<template>
  <div class="login-section onboarding-section">
    <div class="onboarding-container">
      <div class="logo-holder">
        <router-link to="/">
          <img src="/images/logo-light.svg" alt="AudioScribe" />
        </router-link>
      </div>

      <div class="onboarding-progress">
        <div class="progress-steps">
          <div 
            v-for="step in 3" 
            :key="step" 
            class="progress-step" 
            :class="{ 'active': step === currentStep, 'completed': step < currentStep }"
          >
            {{ step }}
          </div>
        </div>
      </div>

      <div class="onboarding-content">
        <component 
          :is="currentScreenComponent" 
          @nextStep="nextStep" 
          @prevStep="prevStep" 
          @complete="completeOnboarding"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useOnboardingStore } from '@/stores/onboarding-store';
import { useWorkSpaceStore } from '@/stores/workspace-store';
import { mapStores } from 'pinia';
import { useRouter } from 'vue-router';
import PrimaryPurposeScreen from '@/components/onboarding/PrimaryPurposeScreen.vue';
import UsageVolumeScreen from '@/components/onboarding/UsageVolumeScreen.vue';
import TeamCollaborationScreen from '@/components/onboarding/TeamCollaborationScreen.vue';

export default {
  name: 'OnboardingFlow',
  components: {
    PrimaryPurposeScreen,
    UsageVolumeScreen,
    TeamCollaborationScreen
  },
  computed: {
    ...mapStores(useOnboardingStore, useWorkSpaceStore),
    currentStep() {
      return this.onboardingStore.currentStep;
    },
    currentScreenComponent() {
      switch (this.currentStep) {
        case 1:
          return 'PrimaryPurposeScreen';
        case 2:
          return 'UsageVolumeScreen';
        case 3:
          return 'TeamCollaborationScreen';
        default:
          return 'PrimaryPurposeScreen';
      }
    }
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  methods: {
    nextStep() {
      this.onboardingStore.nextStep();
      // Scroll to top of the page when moving to next step
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    prevStep() {
      this.onboardingStore.prevStep();
      // Scroll to top of the page when moving to prev step
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async completeOnboarding() {
      const success = await this.onboardingStore.completeOnboarding();
      if (success) {
        // Redirect to main workspace view
        const activeWorkspaceUUID = this.workspaceStore?.activeWorkspaceUUID;
        if (activeWorkspaceUUID) {
          this.router.push({ name: 'workspace-home', params: { workspaceUUID: activeWorkspaceUUID } });
        } else {
          this.router.push({ name: 'home' });
        }
      }
    }
  },
  async created() {
    // Get onboarding status from the API
    await this.onboardingStore.checkOnboardingStatus();
    
    // If onboarding is not required, redirect to home
    if (!this.onboardingStore.isOnboardingRequired) {
      this.$router.push({ name: 'home' });
    }
    
    // Restore progress from localStorage if available
    this.onboardingStore.restoreFromLocalStorage();
  },
  
  watch: {
    // Watch for changes to the current step
    currentStep() {
      // Ensure scroll position resets when the step changes
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/abstracts/variables";
@import "@/assets/abstracts/mixins";

.onboarding-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $secondary;
}

.onboarding-container {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px;
  border-radius: 12px;
  
  @media (min-width: 768px) {
    padding: 40px;
  }
}

.onboarding-content {
  background-color: rgba($black, 0.2);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba($white, 0.05);
  
  @media (min-width: 768px) {
    padding: 32px;
  }
}

.logo-holder {
  text-align: center;
  margin-bottom: 30px;
  
  img {
    max-width: 180px;
    
    @media (min-width: 768px) {
      max-width: 220px;
    }
  }
}

.onboarding-progress {
  margin-bottom: 30px;
  
  .progress-steps {
    display: flex;
    justify-content: center;
    gap: 20px;
    
    .progress-step {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: rgba($black, 0.4);
      border: 2px solid $gray900;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        height: 2px;
        background-color: $gray900;
        width: 20px;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 10px;
      }
      
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        background-color: $gray900;
        width: 20px;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
      }
      
      &:first-child::before,
      &:last-child::after {
        display: none;
      }
      
      &.active {
        background-color: $primary;
        border-color: $primary;
      }
      
      &.completed {
        background-color: $success;
        border-color: $success;
        
        &::after {
          background-color: $success;
        }
      }
      
      &.active + .progress-step::before {
        background-color: $primary;
      }
      
      &.completed + .progress-step::before {
        background-color: $success;
      }
    }
  }
}
</style>