import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router"; // Import useRouter
import {
  isSubscribed,
  getUserWorkspaces,
  createCustomerPortalSession,
} from "@/services/workspace.service";

export const useWorkSpaceStore = defineStore("workspace", () => {
  const router = useRouter(); // Initialize router
  const activeWorkspaceUUID = ref("");
  const workspaces = ref([]); // Store fetched workspaces
  const workspacesLoaded = ref(false); // Track if workspaces have been loaded
  const audioFiles = ref({});
  const activeAudioFile = ref(null);
  const playerCurrentTimeInMilliseconds = ref(0);
  const updatedPlayerTimeInMilliseconds = ref(0);
  const chatToken = ref({});
  const openPopupId = ref(null);
  const fileUploaded = ref(false);
  const fileDeleted = ref(false);
  const checkUserSubscription = ref(false);
  const isSubscribedState = ref(false);
  const subscriptionLoaded = ref(false);
  const subscription = ref(null);
  const billingPortalUrl = ref(null);
  const billingPortalLoaded = ref(false);

  // Centralized computed property to indicate if the subscription is yearly
  const isYearly = computed(() => {
    return subscription.value && subscription.value.billingCycle === "YEAR";
  });

  async function fetchSubscriptionStatus() {
    try {
      const response = await isSubscribed();
      isSubscribedState.value = response.data.isSubscribed;
      // Store only the subscription object from the response
      subscription.value = response.data.subscription;

      if (response.data.isSubscribed) {
        const portalResponse = await createCustomerPortalSession();
        billingPortalUrl.value = portalResponse.data.customerPortalUrl;
      }
    } catch (error) {
      console.error("Failed to fetch subscription status", error);
    } finally {
      subscriptionLoaded.value = true;
      billingPortalLoaded.value = true;
    }
  }

  // Fetch workspaces from backend and determine the active workspace
  async function fetchWorkspaces() {
    try {
      const response = await getUserWorkspaces();
      workspaces.value = response.data;

      // Check localStorage for saved workspace UUID
      const savedWorkspaceUUID = localStorage.getItem(
        "currentSelectedWorkspaceUUID",
      );

      // Validate the saved workspace UUID
      if (
        savedWorkspaceUUID &&
        workspaces.value.some((ws) => ws.workspaceUUID === savedWorkspaceUUID)
      ) {
        activeWorkspaceUUID.value = savedWorkspaceUUID;
      } else if (workspaces.value.length > 0) {
        // Default to the first workspace if no valid saved UUID
        activeWorkspaceUUID.value = workspaces.value[0].workspaceUUID;
        saveActiveWorkspace(activeWorkspaceUUID.value);
      }
      workspacesLoaded.value = true; // Mark workspaces as loaded
    } catch (error) {
      console.error("Failed to fetch workspaces:", error);
    }
  }

  // Save the active workspace UUID in localStorage
  function saveActiveWorkspace(uuid) {
    localStorage.setItem("currentSelectedWorkspaceUUID", uuid);
  }

  // Update active workspace and save to localStorage
  function setActiveWorkspace(uuid) {
    if (workspaces.value.some((ws) => ws.workspaceUUID === uuid)) {
      activeWorkspaceUUID.value = uuid;
      saveActiveWorkspace(uuid);
      router.push({ name: "workspace-home", params: { workspaceUUID: uuid } });
    }
  }

  function toggleFileUploaded() {
    fileUploaded.value = !fileUploaded.value;
  }

  function setActiveWorkspaceUUID(workspaceUUID) {
    activeWorkspaceUUID.value = workspaceUUID;
  }

  function setOpenPopupId(id) {
    openPopupId.value = id;
  }

  // Call the fetchWorkspaces method directly when this store is loaded
  fetchWorkspaces();

  return {
    activeWorkspaceUUID,
    setActiveWorkspaceUUID,
    setActiveWorkspace,
    workspaces,
    workspacesLoaded,
    fetchWorkspaces,
    openPopupId,
    setOpenPopupId,
    audioFiles,
    activeAudioFile,
    playerCurrentTimeInMilliseconds,
    updatedPlayerTimeInMilliseconds,
    fileUploaded,
    fileDeleted,
    toggleFileUploaded,
    chatToken,
    isSubscribedState,
    subscriptionLoaded,
    fetchSubscriptionStatus,
    subscription,
    isYearly, // Expose the computed property so components can use it directly
    billingPortalUrl,
    billingPortalLoaded,
  };
});
