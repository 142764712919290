<template>
  <a class="accessibility" href="#main">Skip to Content</a>
  <noscript>
    <div>Javascript must be enabled for the correct page display</div>
  </noscript>
  <div class="wrapper">
    <div class="content-wrap">
      <NavBar />
      <main class="main content-holder" id="main">
        <TopBar />
        <slot />
      </main>
    </div>
    <PageFooter />
  </div>
  <PopupContainer></PopupContainer>
</template>

<script>
import PageFooter from "@/components/PageFooter.vue";
import NavBar from "@/components/navigation/NavBar.vue";
import TopBar from "@/components/TopBar.vue";
import PopupContainer from "@/components/PopupContainer.vue";

import { useWorkSpaceStore } from "@/stores/workspace-store";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";
import { getUser } from "@/services/workspace.service";

export default {
  name: "PageLayout",
  components: {
    PageFooter,
    NavBar,
    TopBar,
    PopupContainer,
  },
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
  },
  data() {
    return {
      greeting: "Hello from parent",
    };
  },
  async beforeMount() {
    console.log("PageLayout Mounted");
    const user = await getUser(); // Assuming appStore has a getUser action
    console.log(user);
    this.appStore.user = user.data;

    // // Fetch workspaces if not already loaded
    // if (!this.workspaceStore.workspacesLoaded) {
    //   await this.workspaceStore.fetchWorkspaces();
    // }
  },
};
</script>
