<template>
  <SettingsLayout>
    <Suspense>
      <div class="settings-content">
        <div class="heading">
          <h1 class="h2">Billing</h1>
        </div>
        <div class="block">
          <div class="mxw">
            <template
              v-if="
                !workspaceStore.subscriptionLoaded ||
                !workspaceStore.billingPortalLoaded
              "
            >
              Loading billing information...
            </template>
            <template v-else-if="workspaceStore.isSubscribedState">
              <p v-if="workspaceStore.billingPortalUrl">
                To access your billing information please click the link below:
              </p>
              <p v-if="workspaceStore.billingPortalUrl">
                <a :href="workspaceStore.billingPortalUrl">Billing portal</a>
              </p>
            </template>
            <template v-else>
              <p>
                It seems that you are not currently subscribed. Please
                <router-link to="/settings/plans">click here</router-link> to
                view our subscription plans and subscribe.
              </p>
            </template>
          </div>
        </div>
      </div>
      <template #fallback> Loading... </template>
    </Suspense>
  </SettingsLayout>
</template>

<script>
import SettingsLayout from "@/components/SettingsLayout.vue";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { mapStores } from "pinia";

export default {
  name: "SettingsBillingView",
  components: {
    SettingsLayout,
  },
  computed: {
    ...mapStores(useWorkSpaceStore),
  },
  async beforeMount() {
    if (!this.workspaceStore.subscriptionLoaded) {
      await this.workspaceStore.fetchSubscriptionStatus();
    }
  },
};
</script>
