<template>
  <div class="usage-screen">
    <h1>What's your expected usage of AudioScribe?</h1>
    
    <form @submit.prevent="handleSubmit" class="login-form" :class="{ 'error-active': hasErrors }">
      <div class="form-section">
        <h2>How often do you need transcription?</h2>
        <div class="frequency-options">
          <div 
            v-for="option in frequencyOptions" 
            :key="option.value"
            class="option-card"
            :class="{ 'selected': preferences.usageFrequency === option.value }"
            @click="selectFrequency(option.value)"
          >
            <div class="radio-container">
              <input 
                type="radio" 
                :id="option.value" 
                name="usageFrequency" 
                :value="option.value" 
                v-model="preferences.usageFrequency"
              />
              <label :for="option.value">
                <span class="radio-circle"></span>
                <span class="radio-text">{{ option.label }}</span>
              </label>
            </div>
          </div>
        </div>
        
        <div v-if="validationErrors.usageFrequency" class="form-error">
          <span class="error-icon icon-exclamation-mark"></span>
          <span class="error-txt">{{ validationErrors.usageFrequency }}</span>
        </div>
      </div>
      
      <div class="form-section recording-length-section">
        <h2>What's the typical length of your recordings?</h2>
        <div class="length-options">
          <div 
            v-for="option in lengthOptions" 
            :key="option.value"
            class="option-card checkbox-card"
            :class="{ 'selected': preferences.recordingLengths.includes(option.value) }"
            @click.prevent="toggleLength(option.value)"
          >
            <div class="checkbox-container">
              <span class="checkbox-box" :class="{ 'checked': preferences.recordingLengths.includes(option.value) }"></span>
              <span class="checkbox-text">
                <span class="checkbox-title">{{ option.label }}</span>
                <span class="checkbox-description">{{ option.description }}</span>
              </span>
            </div>
          </div>
        </div>
        
        <div v-if="validationErrors.recordingLengths" class="form-error">
          <span class="error-icon icon-exclamation-mark"></span>
          <span class="error-txt">{{ validationErrors.recordingLengths }}</span>
        </div>
      </div>
      
      <div class="navigation-buttons">
        <button type="button" class="btn btn-secondary" @click="$emit('prevStep')">Back</button>
        <input type="submit" class="btn btn-primary" value="Next" :disabled="isSubmitting" />
      </div>
    </form>
  </div>
</template>

<script>
import { useOnboardingStore } from '@/stores/onboarding-store';
import { mapStores } from 'pinia';

export default {
  name: 'UsageVolumeScreen',
  computed: {
    ...mapStores(useOnboardingStore),
    preferences() {
      return this.onboardingStore.preferences;
    },
    validationErrors() {
      return this.onboardingStore.validationErrors;
    },
    isSubmitting() {
      return this.onboardingStore.isSubmitting;
    },
    hasErrors() {
      return Object.keys(this.validationErrors).length > 0;
    }
  },
  data() {
    return {
      frequencyOptions: [
        { value: 'DAILY', label: 'Daily' },
        { value: 'WEEKLY', label: 'Weekly' },
        { value: 'MONTHLY', label: 'Monthly' },
        { value: 'OCCASIONAL', label: 'Occasionally' }
      ],
      lengthOptions: [
        { value: 'SHORT', label: 'Short', description: '< 15 minutes' },
        { value: 'MEDIUM', label: 'Medium', description: '15-30 minutes' },
        { value: 'LONG', label: 'Long', description: '30-60 minutes' },
        { value: 'VERY_LONG', label: 'Very Long', description: '> 60 minutes' }
      ]
    };
  },
  methods: {
    selectFrequency(value) {
      this.preferences.usageFrequency = value;
      this.onboardingStore.validationErrors = {};
    },
    toggleLength(value) {
      // Create a new array to ensure reactivity
      let newRecordingLengths = [...this.preferences.recordingLengths];
      
      if (newRecordingLengths.includes(value)) {
        newRecordingLengths = newRecordingLengths.filter(item => item !== value);
      } else {
        newRecordingLengths.push(value);
      }
      
      // Use direct assignment to ensure reactivity
      this.preferences.recordingLengths = newRecordingLengths;
      this.onboardingStore.validationErrors = {};
    },
    handleSubmit() {
      if (this.onboardingStore.validateCurrentStep()) {
        this.$emit('nextStep');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/abstracts/variables";
@import "@/assets/abstracts/mixins";

.usage-screen {
  h1 {
    font-size: 22px;
    margin-bottom: 24px;
    color: $white;
    text-align: center;
    font-weight: 500;
    
    @media (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
}

.form-section {
  margin-bottom: 30px;
  
  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 500;
    color: $white;
  }
}

.recording-length-section {
  background-color: rgba($black, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-top: 24px;
  border: 1px solid rgba($white, 0.05);
}

.frequency-options,
.length-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 15px;
}

.option-card {
  background-color: rgba($black, 0.2);
  border: 1px solid $gray900;
  border-radius: 10px;
  padding: 14px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  
  &:hover {
    background-color: rgba($primary, 0.05);
  }
  
  &.selected {
    border-color: $primary;
    background-color: rgba($primary, 0.1);
  }
  
  &.checkbox-card {
    display: flex;
    align-items: flex-start;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    
    .checkbox-container {
      position: relative;
      z-index: 2;
    }
  }
}

.radio-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.radio-circle {
  display: inline-block;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid $gray700;
  position: relative;
  margin-right: 12px;
  
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $primary;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
}

input[type="radio"]:checked + label .radio-circle {
  border-color: $primary;
  
  &::after {
    display: block;
  }
}

.checkbox-box {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid $gray700;
  position: relative;
  margin-right: 12px;
  flex-shrink: 0;
  margin-top: 3px;
  transition: all 0.2s ease;
  
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 6px;
    border-left: 2px solid $white;
    border-bottom: 2px solid $white;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    display: none;
  }
  
  &.checked {
    border-color: $primary;
    background-color: $primary;
    
    &::after {
      display: block;
    }
  }
}

.radio-text,
.checkbox-text {
  font-size: 16px;
  color: $white;
  font-weight: 400;
}

.checkbox-text {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  flex: 1;
}

.checkbox-title {
  font-weight: 500;
  margin-bottom: 3px;
}

.checkbox-description {
  font-size: 14px;
  color: rgba($white, 0.6);
  line-height: 1.3;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  
  .btn {
    min-width: 120px;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &.btn-primary {
      background-color: $primary;
      color: $white;
      
      &:hover {
        background-color: darken($primary, 10%);
      }
      
      &:disabled {
        background-color: $gray700;
        cursor: not-allowed;
      }
    }
    
    &.btn-secondary {
      background-color: transparent;
      color: $white;
      border: 1px solid $gray700;
      
      &:hover {
        background-color: rgba($white, 0.05);
        border-color: $gray500;
      }
    }
  }
}

.form-error {
  color: $danger;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  
  .error-icon {
    margin-right: 5px;
  }
}
</style>