<template>
  <nav class="main-nav">
    <NavWorkspaces
      :showNewWorkspacePopup="appStore.showNewWorkspacePopup"
      @workspaceChanged="setActiveWorkspace"
    >
    </NavWorkspaces>
    <NavLinks></NavLinks>
    <NavFooter></NavFooter>
  </nav>
</template>

<script>
import NavWorkspaces from "./NavWorkspaces.vue";
import NavLinks from "./NavLinks.vue";
import NavFooter from "./footer/NavFooter.vue";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

export default {
  name: "MainNav",
  components: {
    NavLinks,
    NavFooter,
    NavWorkspaces,
  },
  computed: {
    ...mapStores(useWorkSpaceStore),
    ...mapStores(useAppStore),
  },
  setup() {
    const workspaceStore = useWorkSpaceStore();

    function setActiveWorkspace(uuid) {
      workspaceStore.setActiveWorkspace(uuid); // Update active workspace in store and localStorage
    }

    return {
      workspaces: workspaceStore.workspaces,
      activeWorkspaceUUID: workspaceStore.activeWorkspaceUUID,
      setActiveWorkspace,
    };
  },
  data() {
    return {
      isWorkspaceViewActive: false,
    };
  },
  methods: {
    toggleWorkspaceView(event) {
      event.preventDefault();
      this.isWorkspaceViewActive = !this.isWorkspaceViewActive;
    },
  },
};
</script>

<style scoped>
/* Example CSS to show the toggle effect */
.active .acc-drop {
  display: block;
}

.acc-drop {
  display: none;
}

.workspace-list .active a {
  font-weight: bold;
  color: #f16622;
  /* Example: Highlight active workspace */
}
</style>
