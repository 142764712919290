<template>
  <div class="btn-group">
    <div class="btn-col">
      <a
        href="#"
        class="btn btn-secondary-outline"
        @click.prevent="openExportPopup"
      >
        <span class="btn-txt">
          <span class="btn-icon icon-download"></span>Export
        </span>
      </a>
    </div>
    <!-- Hidden link for initiating download -->
    <a ref="downloadLink" style="display: none"></a>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

export default {
  name: "ExportButton",
  computed: {
    ...mapStores(useAppStore),
  },
  methods: {
    openExportPopup() {
      this.appStore.showExportPopup = true; // Set the showExportPopup to true to open the ExportPopup
    },
  },
};
</script>
