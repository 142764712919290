<template>
  <div v-if="isDevelopment" class="dev-tools">
    <button 
      class="reset-btn"
      @click="resetOnboardingFlow"
      :disabled="isLoading"
    >
      {{ isLoading ? 'Resetting...' : 'Reset Onboarding Flow' }}
    </button>
    <div class="status-message" v-if="statusMessage">
      {{ statusMessage }}
    </div>
  </div>
</template>

<script>
import { useOnboardingStore } from '@/stores/onboarding-store';
import { ref, computed } from 'vue';

export default {
  name: 'DevResetOnboarding',
  setup() {
    const onboardingStore = useOnboardingStore();
    const statusMessage = ref('');
    const isLoading = ref(false);
    
    // Check if we're in development mode
    const isDevelopment = computed(() => {
      return import.meta.env.VITE_ENV !== 'production';
    });
    
    async function resetOnboardingFlow() {
      isLoading.value = true;
      statusMessage.value = '';
      
      try {
        const result = await onboardingStore.resetOnboarding();
        
        if (result.success) {
          statusMessage.value = result.message || 'Onboarding reset successfully!';
        } else {
          statusMessage.value = result.message || 'Failed to reset onboarding status.';
        }
      } catch (error) {
        statusMessage.value = 'An error occurred while resetting onboarding status.';
        console.error('Error resetting onboarding:', error);
      } finally {
        isLoading.value = false;
        
        // Auto-clear status message after 5 seconds
        setTimeout(() => {
          statusMessage.value = '';
        }, 5000);
      }
    }
    
    return {
      isDevelopment,
      isLoading,
      statusMessage,
      resetOnboardingFlow
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/abstracts/variables";

.dev-tools {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: rgba($black, 0.7);
  border: 1px solid $primary;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.reset-btn {
  background-color: $primary;
  color: $white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: darken($primary, 10%);
  }
  
  &:disabled {
    background-color: $gray700;
    cursor: not-allowed;
  }
}

.status-message {
  margin-top: 8px;
  font-size: 12px;
  color: $white;
  text-align: center;
  max-width: 180px;
}
</style>