<template>
  <li title="Logout">
    <a @click="handleLogout" href="#" class="icon-signout"></a>
  </li>
</template>

<script>
export default {
  name: "LogoutButton",
  methods: {
    handleLogout() {
      // Implement the logout logic here
      this.$auth0.logout();
    },
  },
};
</script>
