<template>
  <SettingsLayout>
    <div class="settings-content">
      <div class="heading">
        <h1 class="h2">General</h1>
      </div>
      <div class="block">
        <div class="mxw">
          <h2 class="h3">Your Workspaces</h2>
          <div
            v-for="(workspace, index) in workspaces"
            :key="workspace.workspaceUUID"
            class="input-row"
          >
            <h3>Workspace {{ index + 1 }}</h3>
            <div class="form-input">
              <input
                type="text"
                v-model="workspace.name"
                @blur="updateWorkspaceName(workspace)"
                @keyup.enter="updateWorkspaceName(workspace)"
              />
              <a
                href="#"
                class="delete"
                @click.prevent="deleteWorkspace(workspace.workspaceUUID)"
              >
                <i class="icon-delete"></i>
              </a>
            </div>
          </div>
          <div class="btn-wrap">
            <a
              href="#"
              class="btn btn-secondary-outline"
              :class="{ disabled: !canCreateWorkspace }"
              @click.prevent="handleAddWorkspaceClick"
            >
              <i class="icon-plus"></i>Add a New Workspace
              <span v-if="!canCreateWorkspace" style="margin-left: 5px">
                <span v-if="isSubscribed">(Limit: 3)</span>
                <span v-else>(Upgrade for more)</span>
              </span>
            </a>
            <div
              v-if="!isSubscribed && workspaces.length >= 1"
              class="upgrade-note"
            >
              <router-link to="/settings/plans" class="upgrade-link">
                Upgrade to Premium for up to 3 workspaces
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SettingsLayout>
</template>

<script>
import SettingsLayout from "@/components/SettingsLayout.vue";
import {
  getUserWorkspaces,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
} from "@/services/workspace.service";
import { useAppStore } from "@/stores/app-store";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { mapStores } from "pinia";

export default {
  name: "SettingsWorkspacesView",
  components: {
    SettingsLayout,
  },
  computed: {
    ...mapStores(useAppStore),
    ...mapStores(useWorkSpaceStore),
    isSubscribed() {
      return this.workspaceStore.isSubscribedState;
    },
    canCreateWorkspace() {
      // Free users can have one workspace, paid users can have up to 3
      if (this.workspaceStore.isSubscribedState) {
        return this.workspaces.length < 3;
      } else {
        return this.workspaces.length < 1;
      }
    },
  },
  data() {
    return {
      workspaces: [],
    };
  },
  mounted() {
    this.loadWorkspaces();
  },
  methods: {
    async loadWorkspaces() {
      try {
        const response = await getUserWorkspaces();
        this.workspaces = response.data;
      } catch (error) {
        this.$toast.warning(`Failed to load workspaces.`);
      }
    },
    async updateWorkspaceName(workspace) {
      if (!workspace.name.trim()) {
        this.$toast.warning("Workspace name cannot be empty.");
        return;
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(workspace.name)) {
        this.$toast.warning(
          "Workspace name invalid. Only alphanumeric characters and spaces are allowed.",
        );
        return;
      }

      try {
        await updateWorkspace(workspace.workspaceUUID, workspace.name);
        this.$toast.success(`Successfully updated workspace name`);
      } catch (error) {
        this.$toast.warning(`Failed to update workspace name`);
      }
    },
    async deleteWorkspace(workspaceUUID) {
      try {
        await deleteWorkspace(workspaceUUID);
        this.workspaces = this.workspaces.filter(
          (ws) => ws.workspaceUUID !== workspaceUUID,
        );
        this.$toast.success(`Successfully deleted workspace`);
      } catch (error) {
        this.$toast.warning(`Failed to delete workspace`);
      }
    },
    handleAddWorkspaceClick() {
      if (this.canCreateWorkspace) {
        this.addNewWorkspace();
      } else if (this.isSubscribed) {
        this.$toast.warning("Maximum limit of 3 workspaces reached.");
      } else {
        this.$toast.warning(
          "Free plan limited to 1 workspace. Please upgrade for up to 3 workspaces.",
        );
      }
    },
    async addNewWorkspace() {
      // Check workspace limit based on subscription status
      if (!this.canCreateWorkspace) {
        if (this.isSubscribed) {
          this.$toast.warning("Maximum limit of 3 workspaces reached.");
        } else {
          this.$toast.warning(
            "Free plan limited to 1 workspace. Please upgrade for up to 3 workspaces.",
          );
        }
        return;
      }

      try {
        const newWorkspaceName = `Workspace${this.workspaces.length + 1}`;
        const response = await createWorkspace(newWorkspaceName, "PRIVATE");
        this.workspaces.push(response.data);
        this.$toast.success(`Successfully added new workspace`);
      } catch (error) {
        this.$toast.warning(`Failed to add new workspace`);
      }
    },
  },
};
</script>

<style scoped>
#email-address {
  background-color: lightgrey;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.upgrade-note {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
}

.upgrade-link {
  color: #f16622;
  text-decoration: underline;
  font-weight: 500;
}

.upgrade-link:hover {
  text-decoration: none;
}
</style>
