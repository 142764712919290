import { callExternalApi } from "./external-api.service";
//import { useAuth0 } from "@auth0/auth0-vue";
import { auth0 } from "./auth0";

const API_SERVER_URL = import.meta.env.VITE_API_SERVER_URL;

export const createWorkspace = async (name, privacy) => {
  console.log("Called the createWorkspace method");

  const config = {
    url: `${API_SERVER_URL}/workspace`,
    method: "POST",
    data: {
      name,
      privacy,
    },
  };
  return await secureApiCallTemplate(config);
};

export const getUserWorkspaces = async () => {
  console.log("Called the getUserWorkspaces method");

  const config = {
    url: `${API_SERVER_URL}/workspace`,
    method: "GET",
  };
  return await secureApiCallTemplate(config);
};

export const getAudioFiles = async (workspaceUUID) => {
  console.log("Called the getAudioFiles method");

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file`,
    method: "GET",
  };
  return await secureApiCallTemplate(config);
};

export const getAudioFile = async (workspaceUUID, audioFileUUID) => {
  console.log("Called the getAudioFiles method");

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}`,
    method: "GET",
  };
  return await secureApiCallTemplate(config);
};

export const updateAudioFile = async (
  workspaceUUID,
  audioFileUUID,
  updateAudioFileDTO,
) => {
  console.log("Called the updateAudioFile method");

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}`,
    method: "PATCH",
    data: updateAudioFileDTO,
  };
  return await secureApiCallTemplate(config);
};

export const createTempAudioFile = async (
  workspaceUUID,
  createTempAudioFileDTO,
) => {
  console.log("Called the createTempAudioFile method");

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/temporary`,
    method: "POST",
    data: createTempAudioFileDTO,
  };
  return await secureApiCallTemplate(config);
};

export const getUser = async () => {
  console.log("Called the getUser method");

  const config = {
    url: `${API_SERVER_URL}/user/byAuth0Id`,
    method: "GET",
  };
  return await secureApiCallTemplate(config);
};

export const initChat = async (workspaceUUID, audioFileUUID) => {
  console.log("Initialize the Chat by getting a Token and the history:");

  const config = {
    url: `${API_SERVER_URL}/chat/init`,
    method: "POST",
    data: {
      workspaceUUID,
      audioFileUUID,
    },
  };
  return await secureApiCallTemplate(config);
};

export const sendChatMessage = async (
  workspaceUUID,
  audioFileUUID,
  message,
) => {
  console.log("Initialize the Chat by getting a Token and the history:");

  const config = {
    url: `${API_SERVER_URL}/chat/message`,
    method: "POST",
    data: {
      workspaceUUID,
      audioFileUUID,
      message,
    },
  };
  return await secureApiCallTemplate(config);
};

export const deleteAudioFile = async (workspaceUUID, audioFileUUID) => {
  console.log(
    `Deleting an audio file with UUID ${audioFileUUID} and workspace UUID ${workspaceUUID}`,
  );

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}`,
    method: "DELETE",
    data: {
      workspaceUUID,
      audioFileUUID,
    },
  };
  return await secureApiCallTemplate(config);
};

export const downloadAudioFile = async (workspaceUUID, audioFileUUID) => {
  console.log("Initialize the Chat by getting a Token and the history:");

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}/download`,
    method: "GET",
    data: {
      workspaceUUID,
      audioFileUUID,
    },
  };
  return await secureApiCallTemplate(config);
};

export const createCheckoutSession = async (billingPeriod, promoCode) => {
  console.log("Create a Checkout Session");

  const config = {
    url: `${API_SERVER_URL}/payment/create-checkout-session`,
    method: "POST",
    data: {
      billingPeriod,
      promoCode,
    },
  };
  return await secureApiCallTemplate(config);
};

export const isSubscribed = async () => {
  console.log("Check if the user is subscribed");

  const config = {
    url: `${API_SERVER_URL}/payment/is-subscribed`,
    method: "POST",
  };

  return await secureApiCallTemplate(config);
};

export const getSettingsUser = async () => {
  console.log("Get the user information");

  const config = {
    url: `${API_SERVER_URL}/payment/user`,
    method: "GET",
  };

  return await secureApiCallTemplate(config);
};

export const updateSettingsUser = async (
  firstName,
  lastName,
  emailPreference,
) => {
  console.log(
    `Update the user information with name ${name} and emailPreference ${emailPreference}`,
  );

  const config = {
    url: `${API_SERVER_URL}/payment/user`,
    method: "POST",
    data: {
      firstName,
      lastName,
      emailPreference,
    },
  };

  return await secureApiCallTemplate(config);
};

export const createCustomerPortalSession = async () => {
  console.log("Create a Checkout Session");

  const config = {
    url: `${API_SERVER_URL}/payment/create-customer-portal-session`,
    method: "POST",
  };
  return await secureApiCallTemplate(config);
};

export const downloadTranscript = async (workspaceUUID, audioFileUUID) => {
  console.log("Download Transcript");

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}/transcript`,
    method: "GET",
    data: {
      workspaceUUID,
      audioFileUUID,
    },
  };
  return await secureApiCallTemplate(config);
};

export const downloadTranscriptByFormat = async (
  workspaceUUID,
  audioFileUUID,
  format,
) => {
  console.log(
    `Downloading transcript for audioFileUUID: ${audioFileUUID} in format: ${format}`,
  );

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}/transcript/download`,
    method: "GET",
    params: {
      format,
    },
    responseType: "blob", // Ensure the response is treated as a file
  };
  return await secureApiCallTemplate(config);
};

export const downloadSummary = async (workspaceUUID, audioFileUUID) => {
  console.log(`Downloading summary for audioFileUUID: ${audioFileUUID}`);

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}/summary/download`,
    method: "GET",
    params: {
      format: "txt",
    },
    responseType: "blob", // Ensure the response is treated as a file
  };
  return await secureApiCallTemplate(config);
};

export const downloadChatHistory = async (workspaceUUID, audioFileUUID) => {
  console.log(`Downloading chat history for audioFileUUID: ${audioFileUUID}`);

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}/audio-file/${audioFileUUID}/chat/download`,
    method: "GET",
    params: {
      format: "txt",
    },
    responseType: "blob", // Ensure the response is treated as a file
  };
  return await secureApiCallTemplate(config);
};

export const hasUserExceededFileUploadLimit = async (workspaceUUID) => {
  console.log("Check if the user has exceeded the file limit");
  console.log("workspaceUUID from hasExceededFileUploadLimit", workspaceUUID);
  const config = {
    url: `${API_SERVER_URL}/daily-file-limit`,
    method: "GET",
  };
  return await secureApiCallTemplate(config);
};

export const updateWorkspace = async (workspaceUUID, name) => {
  console.log(`Updating workspace with UUID ${workspaceUUID} and name ${name}`);

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}`,
    method: "PATCH",
    data: {
      name,
    },
  };
  return await secureApiCallTemplate(config);
};

export const deleteWorkspace = async (workspaceUUID) => {
  console.log(`Deleting workspace with UUID ${workspaceUUID}`);

  const config = {
    url: `${API_SERVER_URL}/workspace/${workspaceUUID}`,
    method: "DELETE",
  };
  return await secureApiCallTemplate(config);
};

export const secureApiCallTemplate = async (configParams, token) => {
  //const { getAccessTokenSilently } = useAuth0();
  //const { loginWithRedirect } = useAuth0();
  let accessToken;
  try {
    accessToken = await getAccessTokenSilentlyOutsideComponent();
    //console.log("accessToken", accessToken);
  } catch (e) {
    if (e.error === "login_required") {
      //loginWithRedirect()
    }
    if (e.error === "consent_required") {
      //loginWithRedirect()
    }
    throw e;
  }

  const config = {
    ...configParams,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

function getCallerFunctionName() {
  const error = new Error();
  const stack = error.stack.split("\n");
  // The third line in the stack trace is the caller function
  const callerLine = stack[3];
  const callerFunctionName = callerLine.match(/at (\w+)/)[1];
  return callerFunctionName;
}

export async function getAccessTokenSilentlyOutsideComponent(options) {
  return auth0.getAccessTokenSilently(options);
}
