<template>
  <!-- Only render the layout if subscription data is loaded -->
  <SettingsLayout v-if="workspaceStore.subscriptionLoaded">
    <div class="settings-content">
      <div class="heading">
        <h1 class="h2">Plans</h1>
      </div>

      <div class="block">
        <div class="mxw">
          <!-- Show the toggle if there's no subscription or if the user is on a monthly plan -->
          <div class="watermark-toggle" v-if="showYearlyToggle">
            <label class="checkbox-wrap">
              <input
                type="checkbox"
                id="toggle"
                v-model="localIsYearly"
                @change="handleYearlyToggle"
              />
              <span class="label" style="padding-right: 20px"
                >Monthly Billing</span
              >
              <span class="toggle">
                <span class="circle"></span>
              </span>
              <span class="label">Yearly Billing</span>
              <a href="#" class="badge">Save 50%</a>
            </label>
          </div>
        </div>
      </div>

      <div class="plans-holder">
        <!-- Free Plan -->
        <div class="plan-single">
          <div class="head">
            <h2 class="h3">Free</h2>
          </div>
          <div class="price">
            <span class="unit">$</span>
            <span class="amount">0</span>
          </div>
          <!-- If user has a subscription, show "Downgrade Plan". Otherwise show "Current Plan" -->
          <a
            v-if="workspaceStore.subscription"
            @click.prevent="navigateToBillingPortal"
            href="#"
            class="plan-btn"
          >
            Downgrade Plan
          </a>
          <a v-else href="#" class="plan-btn">
            <i class="icon-check"></i>Current Plan
          </a>

          <strong class="title">Key Features</strong>
          <ul class="features-list">
            <li>3 free files per day</li>
            <li>AI Summaries</li>
            <li>Powerful AI chat feature</li>
            <li>Search</li>
            <li>Support for audio and video content</li>
            <li>Export in multiple formats (TXT, SRT, VTT)</li>
          </ul>
          <br />
          <ul class="features-list negative-features">
            <li>Transcripts capped to 25 mins</li>
            <li>Lower processing priority</li>
          </ul>
        </div>

        <!-- Standard Plan -->
        <div class="plan-single most-popular">
          <div class="head">
            <h2 class="h3">Standard</h2>
            <!-- Show the "Save $120" badge if user toggles yearly -->
            <a v-if="localIsYearly" href="#" class="badge">Save $120</a>
          </div>
          <div class="price">
            <span class="unit">$</span>
            <span class="amount">{{ displayPrice.amount }}</span>
            <span style="padding-left: 5px">Per month</span>
            <span v-if="localIsYearly" class="note">{{
              displayPrice.note
            }}</span>
          </div>

          <!-- Highlight "current-plan" if the active plan matches -->
          <a
            @click.prevent="handleUpgrade"
            class="plan-btn btn-upgrade"
            :class="{
              'current-plan': isCurrentPlan(
                localIsYearly ? 'standard_yearly_v1' : 'standard_monthly_v1',
              ),
            }"
          >
            {{ upgradeButtonText }}
          </a>

          <strong class="title">Key Features</strong>
          <ul class="features-list">
            <li>Unlimited number of transcripts</li>
            <li>Up to 10-hour transcripts per file</li>
            <li>Highest Processing Priority</li>
            <li>Export in multiple formats (TXT, PDF, SRT)</li>
            <li>Full-index search</li>
            <li>AI Summaries</li>
            <li>Powerful AI chat feature</li>
            <li>Speaker Identification</li>
          </ul>
        </div>

        <!-- Enterprise Plan -->
        <div class="plan-single">
          <div class="head">
            <h2 class="h3">Enterprise</h2>
          </div>
          <div class="price">
            <span class="unit"></span>
            <span class="amount">-</span>
          </div>
          <a href="mailto:support@audioscribe.io" class="plan-btn"
            >Contact Us</a
          >
          <strong class="title">Key Features</strong>
          <ul class="features-list">
            <li>Everything in the Standard Plan</li>
            <li>Running in your own private cloud environment</li>
          </ul>
        </div>
      </div>
    </div>
  </SettingsLayout>

  <!-- Otherwise, show a loading spinner or text -->
  <div v-else style="text-align: center; margin: 2rem">
    <p>Loading your subscription info...</p>
  </div>
</template>

<script>
import SettingsLayout from "@/components/SettingsLayout.vue";
import {
  createCheckoutSession,
  createCustomerPortalSession,
} from "@/services/workspace.service.js";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { mapStores } from "pinia";

export default {
  name: "SettingsPlansView",
  components: {
    SettingsLayout,
  },
  data() {
    return {
      // Tracks the user's desired yearly toggle state.
      // This can differ from the store if the user is subscribed monthly but wants to see yearly pricing.
      localIsYearly: false,
    };
  },
  computed: {
    ...mapStores(useWorkSpaceStore),

    // Return different price info based on localIsYearly
    displayPrice() {
      if (this.localIsYearly) {
        return {
          amount: "10.00",
          note: "Billed Annually as $120",
        };
      }
      return { amount: "19.99", note: "" };
    },

    // Only show the toggle if no subscription or if the subscription is monthly
    showYearlyToggle() {
      const sub = this.workspaceStore.subscription;
      if (!sub) return true;
      return sub.billingCycle === "MONTH";
    },

    // Determines the text for the "Upgrade" button
    upgradeButtonText() {
      const sub = this.workspaceStore.subscription;
      if (sub && sub.status === "ACTIVE") {
        // If user is already on the toggled plan, show "Current Plan"
        if (
          this.isCurrentPlan(
            this.localIsYearly ? "standard_yearly_v1" : "standard_monthly_v1",
          )
        ) {
          return "Current Plan";
        }
        // If toggling from monthly to yearly
        else if (
          this.localIsYearly &&
          sub.currentPlanName === "standard_monthly_v1"
        ) {
          return "Upgrade Plan";
        } else {
          return "Upgrade";
        }
      }
      // If user does not have a subscription, always show "Upgrade Plan"
      return "Upgrade Plan";
    },
  },
  methods: {
    // Called when user clicks the "Upgrade" button
    async handleUpgrade() {
      const sub = this.workspaceStore.subscription;
      if (sub?.status === "ACTIVE") {
        // If user is already subscribed, navigate to Billing Portal
        this.navigateToBillingPortal();
      } else {
        // Otherwise, create a new checkout session
        try {
          const billingPeriod = this.localIsYearly ? "yearly" : "monthly";
          const { data } = await createCheckoutSession(billingPeriod);
          if (data.stripeCheckoutSessionUrl) {
            window.location.href = data.stripeCheckoutSessionUrl;
          }
        } catch (error) {
          console.error("Error during checkout session creation", error);
        }
      }
    },

    // Fetch subscription status from your workspace store
    async fetchSubscriptionStatus() {
      try {
        await this.workspaceStore.fetchSubscriptionStatus();
        // If the user is on a yearly plan, set localIsYearly = true
        const sub = this.workspaceStore.subscription;
        if (sub && sub.billingCycle === "YEAR") {
          this.localIsYearly = true;
        } else {
          this.localIsYearly = false;
        }
      } catch (error) {
        console.error("Error fetching subscription status", error);
      }
    },

    // Opens the Stripe billing portal
    async navigateToBillingPortal() {
      try {
        const response = await createCustomerPortalSession();
        if (response.data.customerPortalUrl) {
          window.location.href = response.data.customerPortalUrl;
        }
      } catch (error) {
        console.error("Failed to navigate to billing portal", error);
      }
    },

    // Called when toggling the checkbox for yearly/monthly
    handleYearlyToggle() {
      // If toggling from monthly to yearly while on a monthly plan,
      // force an update so the button text says "Upgrade Plan"
      const sub = this.workspaceStore.subscription;
      if (
        this.localIsYearly &&
        sub &&
        sub.currentPlanName === "standard_monthly_v1"
      ) {
        this.$forceUpdate();
      }
    },

    // Checks if the user is on a specific plan
    isCurrentPlan(planName) {
      const sub = this.workspaceStore.subscription;
      return sub && sub.currentPlanName === planName;
    },
  },
  mounted() {
    this.fetchSubscriptionStatus();
  },
};
</script>

<style scoped>
.block {
  border-bottom: none;
  display: flex;
  justify-content: center;
}

.note {
  font-size: 12px;
  color: #888;
  display: block;
  margin-top: 5px;
}

/* Highlight the button if it's the current plan */
.current-plan {
  background-color: #ccc;
  pointer-events: none;
}
</style>
