<template>
  <a class="accessibility" href="#main">Skip to Content</a>
  <noscript>
    <div>Javascript must be enabled for the correct page display</div>
  </noscript>
  <div class="wrapper settings-wrapper">
    <SettingsNavigation></SettingsNavigation>
    <slot />
  </div>
  <PopupContainer></PopupContainer>
</template>

<script>
import SettingsNavigation from "@/components/SettingsNavigation.vue";
import PopupContainer from "@/components/PopupContainer.vue";

export default {
  name: "SettingsLayout",
  components: {
    SettingsNavigation,
    PopupContainer,
  },
};
</script>
