<template>
  <SettingsLayout>
    <div class="settings-content">
      <div class="heading">
        <h1 class="h2">General</h1>
      </div>
      <div class="block">
        <div class="mxw">
          <h2 class="h3">Your Profile</h2>
          <div class="input-row">
            <h3>First Name</h3>
            <div class="form-input">
              <input
                type="text"
                v-model="user.firstName"
                placeholder="First Name"
                @blur="updateUser('firstName')"
              />
            </div>
          </div>
          <div class="input-row">
            <h3>Last Name</h3>
            <div class="form-input">
              <input
                type="text"
                v-model="user.lastName"
                placeholder="Last Name"
                @blur="updateUser('lastName')"
              />
            </div>
          </div>
          <div class="input-row">
            <h3>Email</h3>
            <div class="form-input">
              <input
                id="email-address"
                type="text"
                v-model="user.email"
                placeholder="Email"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="mxw">
          <h2 class="h3">Email Preferences</h2>
          <p>Determine how often you receive emails from us</p>
          <div class="radio-list">
            <ul>
              <li>
                <label>
                  <input
                    type="radio"
                    name="emailPreference"
                    value="NOTIFY"
                    v-model="user.emailPreference"
                    @change="updateUser('emailPreference')"
                  />
                  <span class="fake-radio"></span>
                  <span class="fake-label"
                    >Notify me whenever a file finishes processing</span
                  >
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    name="emailPreference"
                    value="DO_NOT_NOTIFY"
                    v-model="user.emailPreference"
                    @change="updateUser('emailPreference')"
                  />
                  <span class="fake-radio"></span>
                  <span class="fake-label">Do not notify me</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="mxw">
          <h2 class="h3">Delete Account</h2>
          <div class="watermark-toggle">
            <div class="btn-wrap">
              <a href="#" @click="showDeleteAccountPopup" class="btn"
                ><i class="icon-plus"></i>DELETE YOUR ACCOUNT</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </SettingsLayout>
</template>

<script>
import SettingsLayout from "@/components/SettingsLayout.vue";
import {
  getSettingsUser,
  updateSettingsUser,
} from "@/services/workspace.service";
import { useAppStore } from "@/stores/app-store";
import { mapStores } from "pinia";

export default {
  name: "SettingsProfileView",
  components: {
    SettingsLayout,
  },
  computed: {
    ...mapStores(useAppStore),
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        emailPreference: "",
      },
    };
  },
  mounted() {
    this.loadUserData();
  },
  methods: {
    async loadUserData() {
      try {
        const response = await getSettingsUser();
        console.log(response.data);
        this.user.firstName = response.data.firstName || ""; // Handle null case
        this.user.lastName = response.data.lastName || ""; // Handle null case
        this.user.email = response.data.email || "";
        this.user.emailPreference =
          response.data.emailPreference || "NO_NOTIFY";
      } catch (error) {
        this.$toast.warning(`Failed to load user data.`);
      }
    },
    async updateUser(field) {
      try {
        // Send firstName, lastName, and emailPreference to update the user profile
        await updateSettingsUser(
          this.user.firstName,
          this.user.lastName,
          this.user.emailPreference,
        );
        this.$toast.success(`Successfully updated ${field}`);
      } catch (error) {
        this.$toast.warning(`Failed to update ${field}`);
      }
    },
    showDeleteAccountPopup() {
      console.log("Showing the Delete Account popup");
      this.appStore.showDeleteAccountPopup = true;
    },
  },
};
</script>

<style scoped>
#email-address {
  background-color: lightgrey;
}
</style>
