import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { useOnboardingStore } from "@/stores/onboarding-store";
import HomeView from "@/views/HomeView.vue";
import CallbackView from "@/views/CallbackView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import SignupView from "@/views/SignupView.vue";
import SettingsProfileView from "@/views/SettingsProfileView.vue";
import SettingsStripeRedirect from "@/views/SettingsStripeRedirect.vue";
import SettingsPlansView from "@/views/SettingsPlansView.vue";
import SettingsBillingView from "@/views/SettingsBillingView.vue";
import PaymentSuccessView from "@/views/PaymentSuccessView.vue";
import PaymentCancelView from "@/views/PaymentCancelView.vue";
import SettingsWorkspacesView from "../views/SettingsWorkspaces.vue";
import OnboardingFlow from "@/views/OnboardingFlow.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      beforeEnter: [
        authGuard,
        async (to, from, next) => {
          // Check if onboarding is required
          const onboardingStore = useOnboardingStore();
          await onboardingStore.checkOnboardingStatus();
          
          if (onboardingStore.isOnboardingRequired) {
            next({ name: "onboarding" });
            return;
          }
          
          // Continue with normal flow
          const workspaceStore = useWorkSpaceStore();
          await workspaceStore.fetchWorkspaces();
          const defaultWorkspaceUUID = workspaceStore.activeWorkspaceUUID;
          next({
            name: "workspace-home",
            params: { workspaceUUID: defaultWorkspaceUUID },
          });
        },
      ],
    },
    {
      path: "/onboarding",
      name: "onboarding",
      component: OnboardingFlow,
      beforeEnter: authGuard,
    },
    {
      path: "/signup",
      name: "signup",
      component: SignupView,
    },
    {
      path: "/settings",
      name: "settings",
      component: SettingsProfileView,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/plans",
      name: "settings-plans",
      component: SettingsPlansView,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/stripe-redirect/:promoCode",
      name: "settings-stripe-redirect",
      component: SettingsStripeRedirect,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/billing",
      name: "settings-billing",
      component: SettingsBillingView,
      beforeEnter: authGuard,
    },
    {
      path: "/settings/workspaces",
      name: "settings-workspaces",
      component: SettingsWorkspacesView,
      beforeEnter: authGuard,
    },
    {
      path: "/:workspaceUUID",
      name: "workspace-home",
      component: HomeView,
      beforeEnter: authGuard,
    },
    {
      path: "/:workspaceUUID/conversations",
      name: "conversations",
      component: () => import("../views/ConversationsView.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/:workspaceUUID/transcription/:audioFileUUID",
      name: "transcription",
      component: () => import("../views/TranscriptionView.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/callback",
      name: "callback",
      component: CallbackView,
    },
    {
      path: "/payment/success",
      name: "payment-success",
      component: PaymentSuccessView,
    },
    {
      path: "/payment/cancel",
      name: "payment-cancel",
      component: PaymentCancelView,
    },
    {
      path: "/:catchAll(.*)",
      name: "Not Found",
      component: NotFoundView,
    },
  ],
});

// Add a global beforeEach navigation guard
router.beforeEach(async (to, from, next) => {
  console.log(
    `Calling the beforeEach navigation guard for route -> ${to.name} from route -> ${from.name}`,
  );
  const workspaceStore = useWorkSpaceStore();

  // Ensure workspaces are loaded before navigation
  if (!workspaceStore.workspaces.length) {
    await workspaceStore.fetchWorkspaces();
  }

  // Check if the route expects a workspaceUUID
  const routeRequiresWorkspace = to.matched.some((record) =>
    record.path.includes(":workspaceUUID"),
  );

  if (routeRequiresWorkspace) {
    const activeWorkspaceUUID = workspaceStore.activeWorkspaceUUID;

    // Redirect if workspaceUUID is missing or invalid
    if (
      !to.params.workspaceUUID ||
      to.params.workspaceUUID !== activeWorkspaceUUID
    ) {
      next({
        name: to.name, // Redirect to the same route
        params: {
          ...to.params,
          workspaceUUID: activeWorkspaceUUID, // Inject active workspace UUID
        },
        query: to.query, // Preserve query parameters
        hash: to.hash, // Preserve hash
      });
      return; // Exit guard after redirecting
    }
  }

  // Proceed to the route if no redirection is needed
  next();
});

// Add a global afterEach navigation guard
router.afterEach(() => {
  window.Intercom("update");
});

export default router;
