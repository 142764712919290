<template>
  <div class="callback-container">
    <div class="loader"></div>
    <p>Processing your authentication...</p>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useOnboardingStore } from "@/stores/onboarding-store";

export default {
  name: "CallBackView",
  setup() {
    const { handleRedirectCallback } = useAuth0();
    const onboardingStore = useOnboardingStore();
    
    return {
      handleRedirectCallback,
      onboardingStore
    };
  },
  async created() {
    // Handle the redirect callback from Auth0
    await this.handleRedirectCallback();
    
    // Check if onboarding is required
    await this.onboardingStore.checkOnboardingStatus();
    
    // Redirect based on onboarding status
    if (this.onboardingStore.isOnboardingRequired) {
      this.$router.push({ name: 'onboarding' });
    } else {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/abstracts/variables";
@import "@/assets/abstracts/mixins";

.callback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $gray1200;
  color: $white;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid $primary;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
