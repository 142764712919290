<template>
  <a class="accessibility" href="#main">Skip to Content</a>
  <noscript>
    <div>Javascript must be enabled for the correct page display</div>
  </noscript>
  <div class="wrapper">
    <header class="header" id="header">
      <div class="container container-sm">
        <div class="logo-login">
          <a href="#"
            ><img
              src="/images/logo-light.svg"
              width="294"
              height="145"
              alt="Audioscribe"
          /></a>
        </div>
      </div>
    </header>
    <div class="page-holder">
      <main class="main" id="main">
        <section class="login-section">
          <span class="login_decor"
            ><img
              src="/images/logo-decor.svg"
              width="1162"
              height="1162"
              alt="Image Description"
          /></span>
          <div class="container container-sm">
            <h1>Redirecting to Stripe</h1>
            <div class="text-holder text-center">
              <!-- Show loading or success message while redirecting -->
              <p v-if="!errorMessage">
                Redirecting to Stripe with discount code {{ promoCode }}
              </p>

              <!-- Show error message if the checkout session creation fails -->
              <p v-else>{{ errorMessage }}</p>
            </div>
          </div>
        </section>
      </main>
    </div>
    <footer class="footer" id="footer">
      <!-- footer content -->
    </footer>
  </div>
</template>

<script>
import { createCheckoutSession } from "@/services/workspace.service.js";

export default {
  name: "SettingsStripeRedirect",
  data() {
    return {
      isYearly: false, // Assume monthly subscription
      promoCode: this.$route.params.promoCode, // Capture promo code from the URL
      errorMessage: null, // Track error message if checkout session creation fails
    };
  },
  methods: {
    async redirectToCheckout() {
      try {
        // Create checkout session with promo code and monthly billing
        const { data } = await createCheckoutSession("monthly", this.promoCode);
        if (data.stripeCheckoutSessionUrl) {
          window.location.href = data.stripeCheckoutSessionUrl; // Redirect to the Stripe checkout page
        }
      } catch (error) {
        console.error("Error creating Stripe checkout session", error);
        // Set the error message and schedule redirection to home page
        this.errorMessage =
          "Failed to create checkout link. Redirecting to the home page...";
        setTimeout(() => {
          this.$router.push("/"); // Redirect to the home page after 3 seconds
        }, 3000);
      }
    },
  },
  mounted() {
    // Automatically redirect to checkout when the page mounts
    this.redirectToCheckout();
  },
};
</script>

<style scoped>
p {
  text-align: center;
  font-size: 18px;
  color: #333;
}
</style>
