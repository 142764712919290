import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { useWorkSpaceStore } from "@/stores/workspace-store";
import { 
  getOnboardingStatus, 
  saveOnboardingPreferences,
  resetOnboardingStatus 
} from "@/services/onboarding.service";

export const useOnboardingStore = defineStore("onboarding", () => {
  // Using router inside actions instead of at store initialization to avoid issues
  const workspaceStore = useWorkSpaceStore();

  // State
  const currentStep = ref(1);
  const isOnboardingRequired = ref(false);
  const isOnboardingCompleted = ref(false);
  const loading = ref(false);
  const preferences = ref({
    primaryPurpose: null,
    otherPurposeDescription: null,
    usageFrequency: null,
    recordingLengths: [],
    collaborationType: null,
    teamSize: null,
  });
  const validationErrors = ref({});
  const isSubmitting = ref(false);
  const maxRetries = 3;

  // Computed properties
  const isValid = computed(() => {
    return Object.keys(validationErrors.value).length === 0;
  });

  // Actions
  function nextStep() {
    if (validateCurrentStep()) {
      if (currentStep.value < 3) {
        currentStep.value++;
        saveToLocalStorage();
        // Ensure we're at the top of the page for the next step
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }

  function prevStep() {
    if (currentStep.value > 1) {
      currentStep.value--;
      // Ensure we're at the top of the page for the previous step
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  function validateCurrentStep() {
    validationErrors.value = {};
    
    switch (currentStep.value) {
      case 1: // Primary Purpose screen
        if (!preferences.value.primaryPurpose) {
          validationErrors.value.primaryPurpose = "Please select a primary purpose";
          return false;
        }
        if (preferences.value.primaryPurpose === "OTHER" && 
            (!preferences.value.otherPurposeDescription || 
             preferences.value.otherPurposeDescription.length < 3 || 
             preferences.value.otherPurposeDescription.length > 100)) {
          validationErrors.value.otherPurposeDescription = 
            "Other description must be between 3-100 characters";
          return false;
        }
        break;
        
      case 2: // Usage Volume screen
        if (!preferences.value.usageFrequency) {
          validationErrors.value.usageFrequency = "Please select how often you'll use AudioScribe";
          return false;
        }
        if (!preferences.value.recordingLengths || preferences.value.recordingLengths.length === 0) {
          validationErrors.value.recordingLengths = "Please select at least one recording length";
          return false;
        }
        break;
        
      case 3: // Team Collaboration screen
        if (!preferences.value.collaborationType) {
          validationErrors.value.collaborationType = "Please select your collaboration type";
          return false;
        }
        if (preferences.value.collaborationType === "TEAM" && !preferences.value.teamSize) {
          validationErrors.value.teamSize = "Please select your team size";
          return false;
        }
        break;
    }
    
    return true;
  }

  async function completeOnboarding() {
    if (!validateCurrentStep()) {
      return false;
    }
    
    isSubmitting.value = true;
    let retries = 0;
    let success = false;
    
    while (retries < maxRetries && !success) {
      try {
        const response = await savePreferences();
        if (response.data && !response.error) {
          isOnboardingCompleted.value = true;
          clearLocalStorage();
          success = true;
          isSubmitting.value = false;
          return true;
        }
      } catch (error) {
        console.error("Error saving preferences:", error);
      }
      
      // Silent retry
      retries++;
      if (retries < maxRetries) {
        await new Promise(resolve => setTimeout(resolve, 1000 * retries)); // Exponential backoff
      }
    }
    
    // Even if all retries fail, we'll consider onboarding complete
    // to avoid blocking their experience
    isOnboardingCompleted.value = true;
    clearLocalStorage();
    
    isSubmitting.value = false;
    return true;
  }

  // LocalStorage methods for persistence
  function saveToLocalStorage() {
    const data = {
      currentStep: currentStep.value,
      preferences: preferences.value
    };
    localStorage.setItem('audioscribe_onboarding', JSON.stringify(data));
  }

  function restoreFromLocalStorage() {
    const savedData = localStorage.getItem('audioscribe_onboarding');
    if (savedData) {
      try {
        const data = JSON.parse(savedData);
        currentStep.value = data.currentStep || 1;
        preferences.value = data.preferences || {
          primaryPurpose: null,
          otherPurposeDescription: null,
          usageFrequency: null,
          recordingLengths: [],
          collaborationType: null,
          teamSize: null,
        };
      } catch (error) {
        console.error("Error restoring onboarding data:", error);
        clearLocalStorage();
      }
    }
  }

  function clearLocalStorage() {
    localStorage.removeItem('audioscribe_onboarding');
  }

  // API methods
  async function checkOnboardingStatus() {
    loading.value = true;
    
    const { data, error } = await getOnboardingStatus();
    
    loading.value = false;
    
    if (data) {
      isOnboardingRequired.value = data.onboardingRequired;
      isOnboardingCompleted.value = data.onboardingCompleted;
      
      // If there are existing preferences, load them
      if (data.currentPreferences && Object.keys(data.currentPreferences).some(key => 
        data.currentPreferences[key] !== null && 
        (!Array.isArray(data.currentPreferences[key]) || data.currentPreferences[key].length > 0)
      )) {
        preferences.value = data.currentPreferences;
      }
      
      // Also check localStorage for in-progress onboarding
      restoreFromLocalStorage();
      
      return { isOnboardingRequired: isOnboardingRequired.value };
    }
    
    return { error };
  }

  async function savePreferences() {
    return await saveOnboardingPreferences(preferences.value);
  }
  
  /**
   * Reset onboarding status for development use
   * Will allow the user to go through the onboarding flow again
   */
  async function resetOnboarding() {
    loading.value = true;
    
    const { data, error } = await resetOnboardingStatus();
    
    loading.value = false;
    
    if (data && data.success) {
      // Reset local state
      isOnboardingRequired.value = true;
      isOnboardingCompleted.value = false;
      currentStep.value = 1;
      
      // Clear preferences
      preferences.value = {
        primaryPurpose: null,
        otherPurposeDescription: null,
        usageFrequency: null,
        recordingLengths: [],
        collaborationType: null,
        teamSize: null,
      };
      
      // Clear local storage
      clearLocalStorage();
      
      return { success: true, message: data.message };
    }
    
    return { 
      success: false, 
      message: error?.message || "Failed to reset onboarding status" 
    };
  }

  return {
    currentStep,
    isOnboardingRequired,
    isOnboardingCompleted,
    preferences,
    validationErrors,
    isSubmitting,
    loading,
    isValid,
    nextStep,
    prevStep,
    validateCurrentStep,
    completeOnboarding,
    checkOnboardingStatus,
    savePreferences,
    saveToLocalStorage,
    restoreFromLocalStorage,
    clearLocalStorage,
    resetOnboarding,
  };
});