<template>
  <!-- Workspace Section-->
  <div class="nav-section">
    <h2>My Workspaces</h2>
    <!-- Workspace content wrapper with border -->
    <div class="workspace-content">
      <ul class="workspace-acc">
        <li :class="{ active: isWorkspaceViewActive }">
          <a href="#" class="acc-opener" @click="toggleWorkspaceView">{{
            activeWorkspaceName
          }}</a>
          <div class="acc-drop">
            <ul class="workspace-list">
              <li
                v-for="(workspace, index) in workspaces"
                :key="index"
                :class="{
                  active: activeWorkspaceUUID === workspace.workspaceUUID,
                }"
              >
                <a
                  href="#"
                  @click="handleWorkspaceSelection(workspace.workspaceUUID)"
                >
                  <span>{{ workspace.initials }}</span
                  >{{ workspace.name }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <!-- Add New Workspace button only shown when dropdown is open -->
      <a
        v-if="isWorkspaceViewActive"
        href="#"
        class="add-link"
        :class="{ disabled: !canCreateWorkspace }"
        @click="handleAddWorkspaceClick"
      >
        + Add a New Workspace
        <span v-if="!canCreateWorkspace" class="limit-text">
          <span v-if="isSubscribed">(Limit: 3)</span>
          <span v-else>
            (<router-link to="/settings/plans" class="upgrade-link-inline"
              >Upgrade for more</router-link
            >)
          </span>
        </span>
      </a>
    </div>
  </div>
  <!-- End Workspace Section-->
</template>

<script>
import { useAppStore } from "@/stores/app-store";
import { useWorkSpaceStore } from "@/stores/workspace-store";

export default {
  name: "NavWorkspaces",
  data() {
    return {
      isWorkspaceViewActive: false,
    };
  },
  methods: {
    toggleWorkspaceView(event) {
      event.preventDefault();
      this.isWorkspaceViewActive = !this.isWorkspaceViewActive;
    },
    setActiveWorkspace(uuid) {
      const workspaceStore = useWorkSpaceStore();
      workspaceStore.setActiveWorkspace(uuid); // Update active workspace in store and localStorage
    },
    handleWorkspaceSelection(uuid) {
      this.setActiveWorkspace(uuid);
      this.isWorkspaceViewActive = false; // Close the dropdown
    },
    toggleNewWorkspacePopup() {
      const appStore = useAppStore();
      appStore.showNewWorkspacePopup = !appStore.showNewWorkspacePopup;
    },
    handleAddWorkspaceClick(event) {
      event.preventDefault();
      if (this.canCreateWorkspace) {
        this.toggleNewWorkspacePopup();
      }
    },
  },
  computed: {
    // Access stores directly in the template without mapping.
    workspaces() {
      return useWorkSpaceStore().workspaces;
    },
    activeWorkspaceUUID() {
      return useWorkSpaceStore().activeWorkspaceUUID;
    },
    activeWorkspaceName() {
      const workspaceStore = useWorkSpaceStore();
      const activeWorkspace = workspaceStore.workspaces.find(
        (ws) => ws.workspaceUUID === workspaceStore.activeWorkspaceUUID,
      );
      return activeWorkspace ? activeWorkspace.name : "My Workspaces";
    },
    isSubscribed() {
      return useWorkSpaceStore().isSubscribedState;
    },
    canCreateWorkspace() {
      // Free users can have one workspace, paid users can have up to 3
      const workspaceStore = useWorkSpaceStore();
      if (workspaceStore.isSubscribedState) {
        return workspaceStore.workspaces.length < 3;
      } else {
        return workspaceStore.workspaces.length < 1;
      }
    },
    workspaceLimit() {
      return useWorkSpaceStore().isSubscribedState ? 3 : 1;
    },
  },
};
</script>
